<template>
  <div class="detail_wrap">
    <div class="title">
      <img src="../../assets/image/course/title.png" alt="" />
    </div>
    <div class="title_desc">课程介绍</div>
    <bottom-point class="bottom_wrap"></bottom-point>

    <div class="course_wrap">
      <div class="course_left">
        <transition enter-active-class="bounceInUp">
          <div class="course_img" v-show="changeCourse">
            <img :src="currentCourse.hImg" alt="" />
          </div>
        </transition>

        <div class="course_intro">
          <div class="intro_title">{{ currentCourse.title }}</div>
          <div class="intro_title_en">{{ currentCourse.enTitle }}</div>
          <div class="intro_desc">{{ currentCourse.desc }}</div>
        </div>
      </div>
      <div class="course_right">
        <p>
          {{ currentInfo.courseIntro }}
        </p>
      </div>
    </div>

    <div class="course_titles">
      <div class="course_titles_wrap">
        <div class="title_list" ref="scrollList">
          <div
            class="title_item"
            v-for="item in currentInfo.courseStageList"
            :key="item.id"
          >
            <div class="item_top">
              <div class="item_num">{{ item.orderBy }}</div>
              <div class="item_name">{{ item.stageName }}</div>
            </div>
            <div class="item_bottom">
              {{ item.stageIntro }}
            </div>
          </div>
        </div>
        <div
          v-if="currentInfo.courseStageList.length > 4"
          class="left_arrow"
          @click="scrollRight"
        >
          <img
            v-if="isLeft"
            src="../../assets/image/course/detail/left_n.png"
            alt=""
          />
          <img
            v-else
            src="../../assets/image/course/detail/left_l.png"
            alt=""
          />
        </div>
        <div
          v-if="currentInfo.courseStageList.length > 4"
          class="right_arrow"
          @click="scrollLeft"
        >
          <img
            v-if="!isLeft"
            src="../../assets/image/course/detail/right_n.png"
            alt=""
          />
          <img
            v-else
            src="../../assets/image/course/detail/right_l.png"
            alt=""
          />
        </div>
        <div class="dot1"></div>
        <div class="dot2"></div>
      </div>
    </div>

    <div class="course_bottom">
      <div class="course_list">
        <transition-group
          enter-active-class="fadeInUp"
          leave-active-class="bounceOut"
          tag="div"
        >
          <div
            class="course_item"
            v-for="(item, index) in courseList"
            :key="item.id"
            @click="itemClick(item, index)"
          >
            <div class="item_img">
              <img :src="item.img" alt="" />
            </div>
            <div class="item_content">
              <div class="content_title">{{ item.title }}</div>
              <div class="content_title_en">{{ item.enTitle }}</div>
            </div>
          </div>
        </transition-group>
      </div>
      <div class="course_content">
        <div class="content_list">
          <div class="content_item">
            <!-- 通过学习游戏世界观设计、游戏系统设计、游戏关卡设计、核心玩法设计、游戏叙事设计等内容，理解游戏策划分工，将创意转变为玩法。 -->
          </div>
        </div>
        <div class="intro_wrap">
          <div class="intro_title_wrap">
            <div class="intro_line">
              <div class="line_dot"></div>
            </div>
            <div class="intro_title">班型介绍</div>
          </div>
          <div class="intro_desc">
            {{ currentInfo.classType }}
          </div>
        </div>
        <div class="intro_wrap">
          <div class="intro_title_wrap">
            <div class="intro_line">
              <div class="line_dot"></div>
            </div>
            <div class="intro_title">就业方向</div>
          </div>
          <div class="intro_desc">{{ currentInfo.jobName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bottomPoint from "@/components/BottomPoint";
require("vue2-animate/dist/vue2-animate.min.css");
import { getCourseInfo } from "@/apis/index";
export default {
  components: { bottomPoint },
  data() {
    return {
      step: 1,
      count: 0,
      isLeft: true,
      courseList: [
        {
          id: 1,
          title: "全栈游戏设计师",
          enTitle: "Full stack designer",
          desc: "4年周末班+2次暑期集训",
          img: require("@/assets/image/course/game.png"),
          hImg: require("@/assets/image/course/h-game.png"),
        },
        {
          id: 2,
          title: "游戏开发工程师",
          enTitle: "Game Development Engineer",
          desc: "1年半周末班+1次暑期集训",
          img: require("@/assets/image/course/art.png"),
          hImg: require("@/assets/image/course/h-art.png"),
        },
        {
          id: 3,
          title: "技术美术",
          enTitle: "Technical Artist",
          desc: "1年半周末班+2次暑期集训",
          img: require("@/assets/image/course/pro.png"),
          hImg: require("@/assets/image/course/h-pro.png"),
        },
        {
          id: 7,
          title: "游戏研发美术",
          enTitle: "Game Development Artist",
          desc: "2年周末班+5个月全日制班+1次暑期集训",
          img: require("@/assets/image/course/pro.png"),
          hImg: require("@/assets/image/course/h-pro.png"),
        },
      ],
      currentCourse: {},
      currentInfo: {},
      changeCourse: true,
    };
  },
  created() {
    // 此处传入要查看的课程index即可
    const index = this.$route.query.index || 0;
    this.currentCourse = this.courseList[index];
    this.courseList.splice(index, 1);
    this.queryForCourseInfo();
  },
  methods: {
    itemClick(item, index) {
      const temp = this.currentCourse;
      this.currentCourse = item;
      this.queryForCourseInfo();
      this.courseList.splice(index, 1);
      setTimeout(() => {
        this.courseList.push(temp);
      }, 1000);
    },
    queryForCourseInfo() {
      getCourseInfo({ id: this.currentCourse.id }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.currentInfo = res.data;
          this.changeCourse = false;
          setTimeout(() => {
            this.changeCourse = true;
          }, 100);
        }
      });
    },
    scrollLeft() {
      var left = 0;
      const inter = setInterval(() => {
        var space = this.step * this.count;
        this.$refs.scrollList.scrollLeft += space;
        left += space;
        if (this.$refs.scrollList.scrollLeft < left) {
          this.count = 0;
          this.isLeft = false;
          clearInterval(inter);
        }
        this.count++;
      }, 10);
    },
    // 右滑动逻辑
    scrollRight() {
      const inter = setInterval(() => {
        this.$refs.scrollList.scrollLeft -= this.step * this.count;
        if (this.$refs.scrollList.scrollLeft === 0) {
          this.count = 0;
          this.isLeft = true;
          clearInterval(inter);
        }
        this.count++;
      }, 10);
    },
  },
};
</script>

<style lang="less" scoped>
.detail_wrap {
  margin: auto;
  margin-top: 6px;
  width: 1760px;
  height: 975px;
  background: url("../../assets/image/course/detail/course_detail_bg.png")
    center center no-repeat;
  background-size: cover;
  position: relative;

  .bottom_wrap {
    position: absolute;
    width: 1680px;
    bottom: 16px;
    left: 40px;
  }

  .title {
    width: 1695px;
    height: 65px;
    margin-left: 31px;
    padding-top: 35px;
  }

  .title_desc {
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    letter-spacing: 16px;
    padding-left: 29px;
    padding-top: 31px;
  }

  .course_wrap {
    width: 1611px;
    height: 160px;
    margin-left: 81px;
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course_left {
      width: 447px;
      height: 100%;
      position: relative;

      .course_img {
        position: absolute;
        left: 0;
        top: 0;
        width: 149px;
        height: 100%;
      }

      .course_intro {
        position: absolute;
        width: 361px;
        height: 100%;
        right: 0;
        top: 0;

        .intro_title {
          font-size: 40px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #8afff2;
          line-height: 40px;
          padding-top: 21px;
        }

        .intro_title_en {
          font-size: 24px;
          font-family: Bedstead;
          color: #ffffff;
          line-height: 24px;
          padding-top: 12px;
          padding-bottom: 11px;
        }

        .intro_desc {
          padding: 0px 9px;
          width: fit-content;
          height: 29px;
          background: #ffffff;
          border-radius: 8px 0px 8px 0px;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 29px;
        }
      }
    }

    .course_right {
      width: 1164px;
      height: 100%;
      background: rgba(32, 37, 46, 0.3);
      box-shadow: inset 0px 1px 5px 0px rgba(138, 255, 242, 0.12),
        inset -4px -4px 8px 0px rgba(138, 255, 242, 0.18);
      border-radius: 19px;
      border-image: linear-gradient(
          270deg,
          rgba(138, 255, 242, 0.26),
          rgba(138, 255, 242, 0)
        )
        1 1;
      border: 1px solid rgba(138, 255, 242, 0.26);
      backdrop-filter: blur(1.4px);
      display: flex;
      // justify-content: center;
      align-items: center;

      p {
        // height: 130px;
        padding-left: 33px;
        padding-right: 33px;
        font-size: 19px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 27px;
        overflow: scroll;
      }
    }
  }

  .course_titles {
    width: 1611px;
    height: 103px;
    margin-left: 81px;
    display: flex;
    justify-content: flex-end;

    .course_titles_wrap {
      width: 1261px;
      height: 103px;
      border-left: 2px solid rgba(255, 255, 255, 0.3);
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      position: relative;

      .left_arrow {
        width: 21px;
        height: 21px;
        position: absolute;
        left: 99px;
        bottom: 24px;
        cursor: pointer;
      }

      .right_arrow {
        width: 21px;
        height: 21px;
        position: absolute;
        right: -10px;
        bottom: 24px;
        cursor: pointer;
      }

      .dot1 {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ffffff;
        left: -5px;
        top: -5px;
      }

      .dot2 {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ffffff;
        right: -5px;
        bottom: -6px;
      }

      .title_list {
        position: absolute;
        left: 143px;
        top: 53px;
        width: 1100px;
        height: 260px;
        // background-color: rebeccapurple;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title_item {
          flex-shrink: 0;
          margin-right: 40px;
          height: 100%;

          &:last-child {
            margin-right: 0;
          }

          .item_top {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 30px;
            .item_num {
              font-size: 37px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 37px;
              margin-right: 2px;
            }

            .item_name {
              padding: 0px 9px;
              width: fit-content;
              height: 29px;
              background: #ffffff;
              border-radius: 8px 0px 8px 0px;
              font-size: 21px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 29px;
            }
          }

          .item_bottom {
            padding-left: 40px;
            width: 200px;
            margin-top: 40px;
            font-size: 19px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 27px;
            // background-color: rebeccapurple;
          }
        }
      }
    }
  }

  .course_bottom {
    width: 1611px;
    margin-left: 81px;
    height: auto;
    display: flex;
    justify-content: space-between;

    .course_list {
      width: 500px;
      padding-top: 12px;

      .course_item {
        width: 100%;
        height: 77px;
        margin-bottom: 107px;
        display: flex;
        justify-content: flex-start;

        .item_img {
          width: 67px;
          height: 77px;
        }

        .item_content {
          margin-left: 11px;

          .content_title {
            font-size: 21px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 21px;
            padding-top: 9px;
          }

          .content_title_en {
            font-size: 24px;
            font-family: Bedstead;
            color: #ffffff;
            line-height: 24px;
            padding-top: 12px;
            opacity: 0.21;
          }
        }
      }
    }

    .course_content {
      flex: 1;
      border-left: 2px solid rgba(255, 255, 255, 0.3);
      height: 362px;
      width: 1118px;
      padding-top: 23px;

      .content_list {
        width: 1080px;
        margin-left: 44px;
        display: flex;
        justify-content: flex-start;
        overflow-x: scroll;

        .content_item {
          flex-shrink: 0;
          width: 187px;
          height: 187px;
          margin-right: 67px;
          font-size: 19px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 27px;
        }
      }

      .intro_wrap {
        margin-top: 44px;

        .intro_title_wrap {
          height: 29px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .intro_line {
            width: 33px;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.3);
            margin-right: 13px;
            position: relative;

            .line_dot {
              position: absolute;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              right: -5px;
              top: -4px;
              background-color: #fff;
            }
          }

          .intro_title {
            padding: 0px 9px;
            width: fit-content;
            height: 29px;
            background: #ffffff;
            border-radius: 8px 0px 8px 0px;
            font-size: 21px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 29px;
          }
        }

        .intro_desc {
          font-size: 19px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 27px;
          padding-left: 44px;
          padding-top: 16px;
        }
      }
    }
  }
}
</style>